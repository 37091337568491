import { detect } from 'detect-browser';
import { Auth, Dashboard, Minimal, PublicFluid } from 'layouts';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import trackEvent from 'redux/actions/Common/trackEvent';
import { AuthenticatedRoutes, PublicRoutes } from 'routes/index';
import {
  Home as HomeView,
  NotFoundCover as NotFoundCoverView,
  SigninCover as SigninCoverView,
  SignupCover as SignupCoverView,
  ResetPassword as ResetPasswordView,
  FirebaseEmailAction as FirebaseEmailActionView,
  AccountSettings as AccountSettingsView,
  SomethingWentWrong as SomethingWentWrongView,
  Unsubscribe as UnsubscribeView,
  ZoomIntegrationHelp as ZoomIntegrationHelpView,
  SalesCallAnalysis as SaleCallAnalysisView,
  PerformanceDashboard as PerformanceDashboardView,
  ConversationAIMain as ConversationAIMainView,
  OneTimePracticeCallInvite as OneTimePracticeCallInviteView,
  TeamSettings as TeamSettingsView,
  Teams as TeamsView,
  Pricing as PricingView,
  CustomerSettings as CustomerSettingsView,
  GoogleCalendarConnect as GoogleCalendarConnectView,
  ZohoCrmConnect as ZohoCrmConnectView,
  ActionItems as ActionItemsView,
  PrivacyPolicy as PrivacyPolicyView,
  ExpertConversation as ExpertConversationView,
  CROInterviews as CROInterviewsView,
  CompanyAccounts as CompanyAccountsView,
  QuestionsFramework as QuestionsFrameworkView,
  CompassHelpCenter as CompassHelpCenterView,
} from 'views';

const Routes = () => {
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const location = useLocation();
  const { pathname, search } = location;
  const dispatch = useDispatch();
  const browser = detect();

  useEffect(() => {
    dispatch(
      trackEvent({
        eventType: 'page_view',
        context: `${pathname}${search}`,
        userId: isLoggedIn ? userInfo?.id : null,
        time: new Date().getTime(),
        os: browser?.os,
        sessionId: isLoggedIn
          ? userInfo?.id
          : sessionStorage.getItem('trackingSessionId'),
        customData: {
          page_path: pathname,
          browser: browser?.name,
          browser_version: browser?.version,
        },
      }),
    );
  }, [pathname]);

  return (
    <Switch>
      {/* -----------Authentication Routes---------  */}
      <PublicRoutes
        exact
        current={'signin'}
        component={SigninCoverView}
        path="/auth/signin"
        layout={Auth}
      />
      <PublicRoutes
        exact
        current={'signup'}
        component={SignupCoverView}
        path="/auth/signup"
        layout={Auth}
      />
      <PublicRoutes
        exact
        current={'resetPassword'}
        component={ResetPasswordView}
        path="/reset/password"
        layout={Auth}
      />
      <PublicRoutes
        exact
        current={'emailAction'}
        component={FirebaseEmailActionView}
        path="/email/action"
        layout={Auth}
      />
      {/* -----------Authentication Routes---------  */}

      {/* -----------Common Routes---------  */}

      <PublicRoutes
        exact
        current={'unsubscribe'}
        component={UnsubscribeView}
        path="/unsubscribe"
        layout={Minimal}
      />
      <PublicRoutes
        exact
        current={'opcEmailInvite'}
        component={OneTimePracticeCallInviteView}
        path="/invite-b7816fc0-406f-43bb-9ba7-f473798a1cdd"
        layout={Minimal}
        noTopbar
      />
      <PublicRoutes
        exact
        current={'privacy'}
        component={PrivacyPolicyView}
        path="/privacy"
        layout={isLoggedIn ? Dashboard : PublicFluid}
      />
      <PublicRoutes
        exact
        current={'zoomIntegrationHelp'}
        component={ZoomIntegrationHelpView}
        path="/zoom-integration-help"
        layout={isLoggedIn ? Dashboard : PublicFluid}
      />
      <PublicRoutes
        exact
        current={'tos'}
        component={PrivacyPolicyView}
        path="/tos"
        layout={isLoggedIn ? Dashboard : PublicFluid}
      />

      {/* -----------Employer Routes---------  */}
      <PublicRoutes
        exact
        current={'home'}
        component={HomeView}
        path="/"
        layout={PublicFluid}
      />
      <AuthenticatedRoutes
        exact
        current={'pricing'}
        component={PricingView}
        path="/pricing"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'accountSettings'}
        component={AccountSettingsView}
        path="/settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'accountSettings'}
        component={AccountSettingsView}
        path="/profile-settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'accountSettings'}
        component={AccountSettingsView}
        path="/profile"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'accountSettings'}
        component={AccountSettingsView}
        path="/integrations"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'accountSettings'}
        component={AccountSettingsView}
        path="/playbook"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'cusotmerSettings'}
        component={CustomerSettingsView}
        path="/customer/settings"
        layout={Dashboard}
      />
      <PublicRoutes
        exact
        current={'googleCalendarConnect'}
        component={GoogleCalendarConnectView}
        path="/oauth/google"
        layout={PublicFluid}
        noTopbar
        noFooter
      />
      <PublicRoutes
        exact
        current={'zohoCrmConnect'}
        component={ZohoCrmConnectView}
        path="/oauth/zoho-crm"
        layout={PublicFluid}
        noTopbar
        noFooter
      />
      {/* {process.env.REACT_APP_TARGET_ENV === 'development' ||
      process.env.REACT_APP_TARGET_ENV === 'local' ? ( */}
      <AuthenticatedRoutes
        exact
        current={'digitalTwin'}
        component={ExpertConversationView}
        path="/digital-twin"
        layout={Dashboard}
      />
      {/* ) : null} */}
      <AuthenticatedRoutes
        exact
        current={'saleCallAnalysis'}
        component={SaleCallAnalysisView}
        path="/sales-call-analysis"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'saleCallAnalysis'}
        component={SaleCallAnalysisView}
        path="/teams/:teamId/sales-call-analysis"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'tasks'}
        component={ActionItemsView}
        path="/tasks"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'performanceDashboard'}
        component={PerformanceDashboardView}
        path="/performance"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'teams'}
        component={TeamsView}
        path="/teams"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'teams'}
        component={TeamsView}
        path="/all-teams"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'teamSettings'}
        component={TeamSettingsView}
        path="/team-settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'teamSettings'}
        component={TeamSettingsView}
        path="/teams/:teamId/team-settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'orgSettings'}
        component={TeamSettingsView}
        path="/org-settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'orgSettings'}
        component={TeamSettingsView}
        path="/org-settings"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'conversationAI'}
        component={ConversationAIMainView}
        path="/conversation-ai"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'conversationAI'}
        component={ConversationAIMainView}
        path="/teams/:teamId/conversation-ai"
        layout={Dashboard}
      />
      {/* <AuthenticatedRoutes
        exact
        current={'conversationAIv2'}
        component={PracticeCallVapiView}
        path="/practice-calls-vapi"
        layout={Dashboard}
      /> */}

      {/* -----------CRO Routes---------  */}
      {/* {process.env.REACT_APP_TARGET_ENV === 'development' ||
      process.env.REACT_APP_TARGET_ENV === 'local' ? ( */}
      <AuthenticatedRoutes
        exact
        current={'croInterviews'}
        component={CROInterviewsView}
        path="/cro-interviews"
        layout={Dashboard}
      />
      {/* ) : null} */}

      {/* {process.env.REACT_APP_TARGET_ENV === 'development' ||
      process.env.REACT_APP_TARGET_ENV === 'local' ? ( */}
      <AuthenticatedRoutes
        exact
        current={'companyAccounts'}
        component={CompanyAccountsView}
        path="/accounts"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'compassFramework'}
        component={QuestionsFrameworkView}
        path="/compass-framework"
        layout={Dashboard}
      />
      <AuthenticatedRoutes
        exact
        current={'companssHelpCenter'}
        component={CompassHelpCenterView}
        path="/compass-helps"
        layout={Dashboard}
      />
      {/* ) : null} */}
      {/* -----------CRO Routes---------  */}
      <PublicRoutes
        exact
        current={'not-found-cover'}
        component={NotFoundCoverView}
        path="/not-found-cover"
        layout={Minimal}
      />

      <PublicRoutes
        exact
        current={'something-went-wrong'}
        component={SomethingWentWrongView}
        path="/something-went-wrong"
        layout={Minimal}
      />
      <Redirect to={'/not-found-cover'} status={404} />
    </Switch>
  );
};

export default Routes;
